'use client'

import { ComponentPropsWithoutRef, ReactNode, useState } from 'react'
import Script from 'next/script'
import { LoadingSpinner, Theme } from '@faml/faml-ui'

import { I18nProvider } from './i18n-provider'
import { GlobalStyles } from '@styles'
import { initialiseSentry, useInitialiseTracking } from '@utils'

type Props = ComponentPropsWithoutRef<typeof I18nProvider>

export const BrowserLayout = ({ locale, children, namespaces }: Props) => {
  return (
    <Theme>
      <ConfigLoader>
        <InitialiseTracking>
          <GlobalStyles />
          <I18nProvider locale={locale} namespaces={namespaces}>
            {children}
          </I18nProvider>
        </InitialiseTracking>
      </ConfigLoader>
    </Theme>
  )
}

const ConfigLoader = ({ children }: { children: ReactNode }) => {
  const [isConfigLoaded, setIsConfigLoaded] = useState(false)

  return (
    <>
      <Script
        src="/config.js"
        onLoad={() => {
          initialiseSentry()
          setIsConfigLoaded(true)
        }}
      />
      {isConfigLoaded ? children : <LoadingSpinner isFullPage />}
    </>
  )
}

// Extracting the initialisation of tracking into a separate component because it should only run after the config has been loaded
const InitialiseTracking = ({ children }: { children: ReactNode }) => {
  useInitialiseTracking()

  return <>{children}</>
}
